import * as React from "react"
import { Modal } from "react-bootstrap"
import "./QAServicesModal.scss"
import { useTranslation } from "gatsby-plugin-react-i18next"
type props = {
  show: boolean
  setShow: (newValue: boolean) => void
  title: React.ReactNode
  content: React.ReactNode
}

export default ({ show, setShow, title, content }: props) => {
  return (
    <Modal
      className="qa-services-modal"
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
    </Modal>
  )
}
