import React from "react"
import "./QASpecialityBox.scss"
import { Button } from "react-bootstrap"
import QAServicesModal from "./QAServicesModal"
import { StaticImage } from "gatsby-plugin-image"

type props = {
  title: string
  content: string
  buttonText: string
  svgComponent: React.ReactNode
  modalContent: React.ReactNode
}

function QASpecialitiesBox({
  title,
  content,
  buttonText,
  modalContent,
  svgComponent
 
}: props) {
  const [showModal, setShowModal] = React.useState(false)
  return (
    <div className="box-speciality-container">
      <div className="rectangle">
        <div className="qa-speciality-svg-container">
        {svgComponent} 
        </div>
      </div>
      <div className="box-speciality-top-section">
        <h1 className="box-speciality-title">{title}</h1>
        <p className="box-speciality-content">{content}</p>
      </div>
      {/* <div className="box-area-bottom-section box-areas-read-more"> */}
      <div className="box-button-footer-container">
        <Button
          className="box-speciality-bottom-section box-speciality-read-more"
          onClick={() => {
            setShowModal(true)
          }}
        >
          {buttonText}
        </Button>
      </div>
      {/* </div> */}
      {showModal && (
        <QAServicesModal
          title={title}
          content={modalContent}
          setShow={setShowModal}
          show={showModal}
        />
      )}
    </div>
  )
}

export default QASpecialitiesBox
