import React from "react"
import "./QAOfferingBoxAreas.scss"
import { Button } from "react-bootstrap"
import QAServicesModal from "./QAServicesModal"

type props = {
  title: string
  content: string
  buttonText: string
  modalContent: React.ReactNode
}

function QAOfferingBoxAreas({
  title,
  content,
  buttonText,
  modalContent,
}: props) {
  const [showModal, setShowModal] = React.useState(false)
  return (
    <div className="box-areas-container">
      <div className="box-area-top-section">
        <h1 className="box-areas-title">{title}</h1>
        <p className="box-areas-content">{content}</p>
      </div>
      {/* <div className="box-area-bottom-section box-areas-read-more"> */}
      <Button
        className="box-area-bottom-section box-areas-read-more"
        onClick={() => {
          setShowModal(true)
        }}
      >
        {buttonText}
      </Button>
      {/* </div> */}
      {showModal && (
        <QAServicesModal
          title={title}
          content={modalContent}
          setShow={setShowModal}
          show={showModal}
        />
      )}
    </div>
  )
}

export default QAOfferingBoxAreas
